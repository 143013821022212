import React, { Component } from "react";
//import Link from 'gatsby-link'

class PageHeader extends Component {
    render() {
        return (
            <div id="page-header" className="secondary-background">
                <div className="container">
                    <div className="row no-margin">
                        <div className="text no-background">
                            <h1 className="white">{this.props.title}</h1>
                            {/* <ul className="breadcrumb white">
                                <li><a href="#">Home</a></li>
                                <li>Blog</li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}
export default PageHeader
