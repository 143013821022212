import React from "react";
//import Link from "gatsby-link";
import PageHeader from "../components/Common/PageHeader";
import "./aboutus.less";
//import Img from "gatsby-image";
//import { just_excerpt } from "../utils/textutil";
import Layout from "../components/layout"


const TeamPage = ({ data }) => (
  <Layout>
  <div id="main-wrap">
    {" "}
    {/* Main Wrap  */}
    {/* Page Content  */}
    <div id="page-content" className="header-static">
      {/* Page Header  */}
      <PageHeader title="團隊介紹">
        <span />
      </PageHeader>
      {/* END Page Header  */}
      <div id="home-wrap" className="content-section fullpage-wrap">
        <div className="container">
          <div className="col-md-2" />
          <div className="col-md-8 padding-leftright-null">
            <div className=" padding-onlytop-lg padding-md-top padding-md-bottom-lg">
              {/* <h2 className="text-center margin-bottom-small">關於我們</h2> */}
              <p>
              首席科學家 介紹更新<br />
<br />
陳俊龍（C.L.Philip Chen)博士，我國自動化學會副理事長，國家千人學者、國家特聘專家，澳門科協副會長，澳門大學講座教授，科技學院前院長。陳教授是IEEE Fellow（院／會士）、美國科學促進會AAAS Fellow（院／會士）、國際模式識別IAPR fellow（院／會士）、歐洲科學院院士、歐洲科學與藝術院院士、國際系統與控制論科學院IASCYS院士、自動化學會（CAA）、以及香港工程師學會（HKIE）Fellow。陳教授現任IEEE系統人機及智能學會的SCI期刊主編，（IEEE Trans, on Systems, Man and Cybernetic:Systems), 曾任該學會國際總主席（2012-2013），多次任SMCS IEEE Fellow Committee 主席。陳教授主要科研在智能系統與控制、計算智能、混合智能、數據科學方向，是  Clarivate Analytiss 計算機學科高被引科學家，陳教授的科研包括國家基金委重點項目及面上項目在人工智能方向的科研，以及多項澳門科學基金委的資助，並獲得兩次澳門自然科學獎。陳教授是美國工學技術教育認證會（ABET）評審委員。澳門大學工程學科及計算機工程，獲得國際華盛頓協議，及首爾協議的認證，是陳教授對澳門工程教育的至高貢獻，擔任院長期間帶領澳門大學的工程學科及計算機學科雙雙進入世界大學學科排名前200名。2016年他獲得母校普渡大學的“傑出電機及計算機工程獎”，2018年獲得IEEE系統科學控制論的最高學術維納獎(Norbert Wiener Awards)。

<br /><br /><br />

              </p>
            </div>
          </div>
          <div className="col-md-2" />
        </div>
      </div>
    </div>
    {/* END Page Content */}
    {/* Main Wrap  */}
  </div>
  </Layout>
);

export default TeamPage;
